<template>
        <div class="login" v-if="!name">
        <h3>Admin Chat</h3>
        <p>Escribe nombre de la sala</p>
        <input type="text" v-model="chatName" />
            <br><br><br>
            <button class="btn btn-primary" v-bind:disabled="chatName != ''?false:true"  @click="addNewChat">Agregar</button>
      </div>
    <div class="message-body">
    
            <div v-for="chat in listChats" :key="chat" class="box-chat">
                <div class="soy-yo">
                <span>{{ chat.chatName }}</span>
                <span>{{ chat.date }}</span>
                <span class="btn-delete" @click="deleteMessage(chat)">x</span>
                </div>
            </div>
     </div>
</template>

<script>
import fire from "../fire";
export default {

    name: "App",
    data() {
      return {
        chatName: "",
        listChats: []
      };
    },
    methods: {
      addNewChat() {
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth()+1; 
        const yyyy = today.getFullYear();
        if(dd<10){
            dd=`0${dd}`;
        } 
        if(mm<10){
            mm=`0${mm}`;
        } 
        const chat = {
          chatName: this.chatName,
          date: `${dd}-${mm}-${yyyy}`
        };
        fire
          .database()
          .ref('ChatName')
          .push(chat);
        this.chatName = "";
        /* this.name = this.name + this.rmdnb; */
      },
      deleteMessage(chat){
        fire.database().ref('ChatName').child(chat['id']).remove();
      }
    },
    mounted() {
      let dom = this;
      const itemsRef = fire.database().ref('ChatName');
      itemsRef.on("value", snapshot => {
        let data = snapshot.val();
        if (data!=null) {
          let listChats = [];
          Object.keys(data).forEach(key => {
            listChats.push({
              id: key,
              chatName: data[key].chatName,
              date: data[key].text
            });
          });
          dom.listChats = listChats;
        }else{
           dom.listChats = [];
        }
      });
    }
}
</script>

<style>

</style>